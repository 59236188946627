import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import LoginView from "@/views/LoginView.vue";
import CommunityHomeVue from "@/views/CommunityHome.vue";
import ContactView from "@/views/ContactView.vue";
import AssociationView from "@/views/AssociationView.vue";
import ProfileView from "@/views/ProfileView.vue";
import { useAuthStore } from "@/stores/auth";
import RegistrationView from "@/views/RegistrationView.vue";
import VotesView from "@/views/VotesView.vue";
import EventsView from "@/views/EventsView.vue";
import PaymentView from "@/views/PaymentView.vue";
import PaymentSuccessView from "@/views/PaymentSuccessView.vue";
import PasswordResetConfrim from "@/views/PasswordResetConfirm.vue";
import PasswordReset from "@/views/PasswordReset.vue";
import GalleryView from "@/views/GalleryView.vue";
import DoggoView from "@/views/DoggoView.vue";
import VoteDetailView from "@/views/VoteDetailView.vue";
import CreateVoteView from "@/views/CreateVoteView.vue";
import HOABulletin from "@/views/HOABulletin.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    props: true,
  },
  {
    path: "/password_reset",
    name: "password_reset",
    component: PasswordReset,
  },
  {
    path: "/reset_confirm",
    name: "reset_confirm",
    component: PasswordResetConfrim,
    props: true,
  },
  {
    path: "/community",
    name: "community",
    component: CommunityHomeVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/hoa",
    name: "association",
    component: AssociationView,
  },
  {
    path: "/community/bulletin",
    name: "bulletin",
    component: HOABulletin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegistrationView,
  },
  {
    path: "/votes",
    name: "Vote",
    component: VotesView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/votes/create",
    name: "Create Vote",
    component: CreateVoteView,
  },
  {
    path: "/votes/detail/:voteHash",
    name: "vote_detail",
    component: VoteDetailView,
    props: true,
  },
  {
    path: "/events",
    name: "event",
    component: EventsView,
  },
  {
    path: "/payments",
    name: "payment",
    component: PaymentView,
  },
  {
    path: "/payments/success/",
    name: "payment_success",
    component: PaymentSuccessView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
  },
  {
    path: "/doggos",
    name: "doggos",
    component: DoggoView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  if (to.meta.requiresAuth) {
    console.log("router requires auth");
    console.log(to);
    const status = auth.quick_verify();
    if (status) {
      console.log(
        "Auth store is telling me that I am logged in so I am preceeding"
      );
      console.log(to.path);
      if (to.path === "/login") auth.logout();
      next();
    } else next("/login");
  } else {
    next();
  }
});

export default router;
