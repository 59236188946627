import { defineStore } from "pinia";
import { HoodApi } from "@/services/HoodApi";

interface Payment {
  id: number;
  house: House;
  stripe_reference: string;
  stripe_secret: string;
  invoice_reference: string;
  amount: number;
  status: string;
  user: UserDetails;
  created_on: string;
}

interface AuthState {
  is_staff: boolean;
  email: string;
  first_name: string;
  last_name: string;
  user_id: number;
  communication: string;
  house: House;
  token: string;
  refresh: string;
  profile: string | null;
  phone: string;
  payments: Payment[];
  directory_enabled: boolean;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: (): AuthState => ({
    is_staff: false,
    email: "",
    first_name: "",
    last_name: "",
    user_id: -1,
    profile: null,
    phone: "",
    communication: "",
    directory_enabled: false,
    house: {
      id: -1,
      house_number: "",
      street: "",
      status: "",
    },
    token: "",
    refresh: "",
    payments: [],
  }),
  getters: {
    loggedIn: (state) => {
      const token = localStorage.getItem("token");
      const refresh = localStorage.getItem("refresh");
      console.log(token, refresh);
      if (state.token && state.refresh) {
        return true;
      } else {
        console.log("i do not think you are logged in");
        return false;
      }
    },
    staff: (state) => {
      return state.is_staff;
    },
    user_details: (state) => {
      return state;
    },
    prev_payments: (state) => {
      return state.payments;
    },
    house_details: (state) => {
      return state.house.house_number + " " + state.house.street;
    },
  },
  actions: {
    async login(email: string, password: string) {
      try {
        const data = await HoodApi.login(email, password);
        console.log(data);
        this.token = data.access;
        this.refresh = data.refresh;
        localStorage.setItem("token", data.access);
        localStorage.setItem("refresh", data.refresh);
        await this.fetch_user_data();
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async logout(): Promise<void> {
      localStorage.clear();
      this.token = "";
      this.refresh = "";
      this.email = "";
      this.first_name = "";
      this.last_name = "";
      this.house.house_number = "";
      this.house.street = "";
      this.house.status = "";
      this.house.id = -1;
      this.is_staff = false;
      this.phone = "";
      this.communication = "";
      this.directory_enabled = false;
      return;
    },
    async fetch_user_data() {
      try {
        const data = await HoodApi.fetch_user_details();
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.email = data.email;
        this.user_id = data.id;
        this.house.id = data.my_house.id;
        this.house.house_number = data.my_house.house_number;
        this.house.street = data.my_house.street;
        this.house.status = data.my_house.status;
        this.profile = data.profile;
        this.payments = data.my_payments;
        this.is_staff = data.is_staff;
        this.phone = data.phone;
        this.communication = data.communication;
        this.directory_enabled = data.directory_enabled;

        console.log(data);
        return;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    quick_verify() {
      console.log("Running quick check");
      const token = localStorage.getItem("token");
      const refresh = localStorage.getItem("refresh");
      this.token = token ? token : "";
      this.refresh = refresh ? refresh : "";
      if (token && token !== "undefined") return true;
      else return false;
    },
    async verify_status() {
      try {
        //check for token
        console.log("Checking Auth");
        const token = localStorage.getItem("token");
        const refresh = localStorage.getItem("refresh");
        this.token = token ? token : "";
        this.refresh = refresh ? refresh : "";
        console.log(token);
        if (token && token !== "undefined") {
          this.fetch_user_data();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
