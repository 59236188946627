
import { defineComponent, PropType } from "vue";
import { Editor, EditorContent } from "@tiptap/vue-3";

export default defineComponent({
  components: {
    EditorContent,
  },
  props: {
    editor: {
      type: Object as PropType<Editor | undefined>,
      default: null,
    },
  },
});
