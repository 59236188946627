import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";

interface TokenResponse {
  access: string;
  refresh: string;
}

interface VoteObj {
  title: string;
  description: string;
  vote_type: string;
  required_response: number;
  response_deadline: string;
  anonymous: boolean;
  status: string;
}

// const baseUrl = "http://127.0.0.1:8000/api/";

// const baseUrl = "https://foxwood-neighborhood.herokuapp.com/api/";

const baseUrl = process.env.VUE_APP_API_BASE_URL as string;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
  headers: {
    Authorization: localStorage.getItem("token")
      ? "JWT " + localStorage.getItem("token")
      : false,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
/** Add the token to every outgoing request */
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (config.headers)
    config.headers.Authorization = token ? `JWT ${token}` : "";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (typeof error == "undefined") {
      console.log("This would be a server error");
      return Promise.reject(error);
    }
    console.log(error.response);
    console.log(originalRequest.url);

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      originalRequest.url === "/token/refresh/"
    ) {
      console.log("Ok I am going to reject the promise");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized" &&
      originalRequest.url !== "/token/refresh/"
    ) {
      const refreshToken = localStorage.getItem("refresh");
      if (refreshToken && refreshToken !== "undefined") {
        console.log(refreshToken);
        console.log("Here is the decoded refresh");
        const tokenParts = JSON.parse(window.atob(refreshToken.split(".")[1]));
        console.log(tokenParts);
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp > now) {
          console.log("Ok the expiration is fine");
          return axiosInstance
            .post("/token/refresh/", {
              refresh: refreshToken,
            })
            .then((response) => {
              console.log("here is the refresh response");
              localStorage.setItem("token", response.data.access);
              axiosInstance.defaults.headers.common["Authorization"] =
                "JWT " + response.data.access;
              originalRequest.headers["Authorization"] =
                "JWT " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log("there was an error refreshing");
              console.log(err);
              router.push({ path: "/login", replace: true });
              return;
            });
        } else {
          console.log("Refresh token has expired");
          const auth = useAuthStore();
          auth.logout();
          router.push({ path: "/login", replace: true });
          return "refresh expired";
        }
      } else {
        console.log("we have no refresh token");
        router.push("/login");
      }
    }
  }
);

export const HoodApi = {
  login: async (email: string, password: string): Promise<TokenResponse> => {
    try {
      console.log("We should be loggin in");
      const response = await axios.post(`${baseUrl}token/`, {
        email,
        password,
      });
      localStorage.setItem("token", response.data.success);
      localStorage.setItem("refresh", response.data.refresh);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  register_user: async (userinfo: any, house_info: House): Promise<any> => {
    try {
      const response = await axios.post(`${baseUrl}user/register/`, {
        user: userinfo,
        house: house_info,
      });
      console.log(response);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  change_profile_pic: async (file: any): Promise<any> => {
    try {
      const response = await axiosInstance.post(
        `${baseUrl}user_details/`,
        file
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  request_password_reset: async (email: string) => {
    try {
      const response = await axios.post(`${baseUrl}password_reset/`, {
        email: email,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  change_password: async (password: string, token: string) => {
    try {
      const response = await axios.post(`${baseUrl}password_reset/confirm/`, {
        password: password,
        token: token,
      });
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  update_user_details: async (dataObj: any): Promise<any> => {
    try {
      const response = await axiosInstance.post(
        `${baseUrl}user_details/`,
        dataObj
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  fetch_user_details: async (): Promise<UserDetails> => {
    try {
      const response = await axiosInstance.get(`${baseUrl}user_details/`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  fetch_posts: async (category = "all", filter?: string): Promise<any> => {
    try {
      const response = await axiosInstance.get(
        `${baseUrl}posts/category/${category}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  get_covenants: async (): Promise<any> => {
    try {
      const response = await axiosInstance.get(`${baseUrl}covenants/`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  make_post: async (post: NewPost): Promise<Post> => {
    try {
      const formData = new FormData();
      formData.append("title", post.title);
      formData.append("description", post.description);
      formData.append("type", post.type);
      if (post.image) {
        formData.append("image", post.image);
      }
      const response = await axiosInstance.post(`${baseUrl}posts/`, formData);
      console.log(response.data);
      return response.data as Post;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  make_comment: async (comment: {
    post: number;
    content: string;
  }): Promise<Comment> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}comments/`, comment);
      return response.data;
    } catch (e) {
      console.log(e);

      throw e;
    }
  },
  house_lookup: async (term: string): Promise<House[]> => {
    try {
      const response = await axiosInstance.get(
        `${baseUrl}houses?search=${term}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  get_votes: async (): Promise<Vote[]> => {
    try {
      const response = await axiosInstance.get(`${baseUrl}votes/`);
      console.log(response.data);
      const votes = response.data;
      return votes;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  cast_ballot: async (ballot: BallotObj): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}ballots/`, ballot);
      console.log(response);
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  create_dues_payment: async (invoice = "na"): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}create_intent/`, {
        invoice: invoice,
      });
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
  update_payment_status: async (
    stripe_status: string,
    payment_id: number
  ): Promise<any> => {
    try {
      const response = await axiosInstance.patch(
        `${baseUrl}payments/{payment_id}/`,
        {
          stripe_status: stripe_status,
        }
      );
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  create_payment_record: async (
    stripe_ref: string,
    stripe_status: string,
    amount: number
  ): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}payments/`, {
        stripe_reference: stripe_ref,
        status: stripe_status,
        amount: amount,
      });
      console.log(response.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  create_a_vote: async (vote: VoteObj): Promise<void> => {
    console.log("this is the create a vote method in the api");
    console.log(vote);
  },
  create_contact_request: async (
    email: string,
    message: string,
    phone?: string
  ): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}contact/`, {
        sender: email,
        phone: phone ? phone : "",
        message: message,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  fetch_events: async (): Promise<any> => {
    try {
      const response = await axiosInstance.get(`${baseUrl}events/`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetch_photos: async (): Promise<any[]> => {
    try {
      const response = await axiosInstance.get(`${baseUrl}photos/`);
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  upload_photo: async (fileObj: any): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}photos/`, fileObj);
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  tag_lookup: async (term: string): Promise<any> => {
    try {
      const response = await axiosInstance.get(
        `${baseUrl}tags/?search=${term}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  create_tag: async (tag: string): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}tags/`, {
        text: tag,
      });
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  get_photos_by_tag: async (tag: string): Promise<any> => {
    try {
      const response = await axiosInstance.post(`${baseUrl}photos_by_tag/`, {
        tags: tag,
      });
      console.log(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};
