import { defineStore } from "pinia";
import { HoodApi } from "@/services/HoodApi";

interface VoteStoreState {
  votes: { [key: string]: Vote };
  ballots: { [key: string]: Ballot };
  total_homes: number;
}

export const useVoteStore = defineStore({
  id: "votes",
  state: (): VoteStoreState => ({
    votes: {},
    ballots: {},
    total_homes: 166,
  }),
  getters: {
    active_votes: (state) => {
      const data = Object.values(state.votes);
      return data.filter((ele) => ele.status === "active");
    },
    did_i_vote: (state) => (user_id: number, vote_id: number) => {
      const data = state.votes[vote_id].ballots;
      console.log(data);
      return data.filter((ele) => ele.user === user_id);
    },
    all_votes: (state) => {
      return state.votes;
    },
  },
  actions: {
    async cast_ballot(ballot_obj: BallotObj): Promise<string> {
      try {
        this.votes = {};
        const ballot = await HoodApi.cast_ballot(ballot_obj);
        console.log(ballot);
        return "success";
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async get_votes(): Promise<void> {
      try {
        const response = await HoodApi.get_votes();
        console.log(response);
        const mapped = response.map((item) => ({ [item.id]: item }));
        const newObj = Object.assign({}, ...mapped);
        this.votes = newObj;
        console.log(newObj);
      } catch (e) {
        console.log(e);
      }
    },
  },
});
