
import { defineComponent, ref, PropType, onBeforeUnmount } from "vue";
import { Editor } from "@tiptap/vue-3";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import EditorContentWrapper from "./EditorContentWrapper.vue";

export default defineComponent({
  components: {
    EditorContentWrapper,
  },
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const editor = ref<Editor | undefined>();

    const syncModelValue = () => {
      emit("update:modelValue", editor.value?.getHTML());
    };

    editor.value = new Editor({
      extensions: [Document, Paragraph, Text],
      content: props.modelValue,
      onUpdate: syncModelValue,
    });

    onBeforeUnmount(() => {
      if (editor.value) {
        editor.value.destroy();
      }
    });

    return {
      editor,
    };
  },
});
