import { defineStore } from "pinia";
import { HoodApi } from "@/services/HoodApi";

interface PostStoreState {
  posts: Post[];
}

export const usePostStore = defineStore({
  id: "posts",
  state: (): PostStoreState => ({
    posts: [],
  }),
  getters: {
    active_posts: (state) => {
      return state.posts;
    },
  },
  actions: {
    async fetch_posts(filter?: string): Promise<Post[]> {
      if (filter) {
        try {
          const posts = await HoodApi.fetch_posts(filter);
          console.log(posts);
          this.posts = posts;
          return posts;
        } catch (err) {
          console.log(err);
          throw err;
        }
      } else {
        try {
          const posts = await HoodApi.fetch_posts();
          console.log(posts);
          this.posts = posts;
          return posts;
        } catch (err) {
          console.log(err);
          throw err;
        }
      }
    },
    async make_post(newPost: NewPost): Promise<Post> {
      try {
        const post = await HoodApi.make_post(newPost);
        this.posts.unshift(post);
        return post;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    add_comment_to_post(post_id: number, comment: Comment): void {
      const index = this.posts.findIndex((ele) => {
        return ele.id == post_id;
      });
      this.posts[index].comments.push(comment);
    },
    async make_comment(
      post_id: number,
      comment_text: string
    ): Promise<Comment> {
      try {
        const newComment = await HoodApi.make_comment({
          post: post_id,
          content: comment_text,
        });
        this.add_comment_to_post(post_id, newComment);
        return newComment;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
});
