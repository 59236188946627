<template>
  <aside
    class="relative overflow-hidden text-white bg-gradient-to-r from-sky-400 to-blue-700"
  >
    <div
      class="relative z-10 max-w-screen-xl px-4 py-24 mx-auto sm:px-6 lg:px-8"
    >
      <div class="max-w-xl space-y-8 text-center sm:text-right sm:ml-auto">
        <h2 class="text-4xl font-bold sm:text-5xl">
          Foxwood Crossing
          <span class="hidden sm:block"> Neighborhood </span>
        </h2>

        <p class="hidden sm:block sm:max-w-lg sm:ml-auto sm:text-lg">
          Foxwood Crossing is neighbor's neighborhood where all are welcome. We
          like to think of our neighborhood as a community and whether you're a
          current resident or considering moving in, welcome.
        </p>

        <router-link
          class="inline-flex items-center px-6 py-3 font-medium bg-black rounded-full hover:opacity-75"
          to="/login"
        >
          Current Homeowners

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 ml-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </router-link>
      </div>
    </div>

    <div class="absolute inset-0 w-full h-full mix-blend-multiply">
      <img
        src="../assets/Entrance.png"
        alt="New robot toy"
        class="absolute inset-0 object-cover object-top w-1/2 h-full"
      />
    </div>
  </aside>

  <section class="mx-auto ml-10">
    <div class="max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div
        class="grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-x-16 lg:items-center"
      >
        <div class="max-w-lg mx-auto text-center lg:text-left lg:mx-0">
          <h2 class="text-3xl font-bold sm:text-4xl">Considering a home?</h2>

          <p class="mt-4 text-gray-600">
            You only get so much information from Zillow, if you have any
            questions for the residents of this neighborhood, reach out to our
            friendly HOA or use some of the helpful links to right.
          </p>

          <a
            class="inline-flex items-center px-8 py-3 mt-8 text-white bg-indigo-600 border border-indigo-600 rounded hover:bg-transparent hover:text-indigo-600 active:text-indigo-500 focus:outline-none focus:ring"
            href="/contact"
          >
            <span class="text-sm font-medium">
              Contact Neighborhood Association
            </span>

            <svg
              class="w-5 h-5 ml-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </a>
        </div>

        <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
          <a
            class="block p-4 border border-gray-100 shadow-sm rounded-xl focus:outline-none focus:ring hover:border-gray-200 hover:ring-1 hover:ring-gray-200"
            href="https://www.whitnall.com/"
          >
            <span class="inline-block p-3 rounded-lg bg-gray-50">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                <path
                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                ></path>
              </svg>
            </span>

            <h6 class="mt-2 font-bold">School District</h6>

            <p class="hidden sm:mt-1 sm:text-sm sm:text-gray-600 sm:block">
              Learn more about the Whitnall School District.
            </p>
          </a>

          <a
            class="block p-4 border border-gray-100 shadow-sm rounded-xl focus:outline-none focus:ring hover:border-gray-200 hover:ring-1 hover:ring-gray-200"
            href="https://www.ci.greenfield.wi.us/283/Parks-and-Recreation"
          >
            <span class="inline-block p-3 rounded-lg bg-gray-50">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                <path
                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                ></path>
              </svg>
            </span>

            <h6 class="mt-2 font-bold">Parks</h6>

            <p class="hidden sm:mt-1 sm:text-sm sm:text-gray-600 sm:block">
              Greenfield Parks Department
            </p>
          </a>

          <a
            class="block p-4 border border-gray-100 shadow-sm rounded-xl focus:outline-none focus:ring hover:border-gray-200 hover:ring-1 hover:ring-gray-200"
            href="https://www.greenfieldlibrary.org/"
          >
            <span class="inline-block p-3 rounded-lg bg-gray-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                />
              </svg>
            </span>

            <h6 class="mt-2 font-bold">Library</h6>

            <p class="hidden sm:mt-1 sm:text-sm sm:text-gray-600 sm:block">
              Greenfield Public Library
            </p>
          </a>

          <a
            class="block p-4 border border-gray-100 shadow-sm rounded-xl focus:outline-none focus:ring hover:border-gray-200 hover:ring-1 hover:ring-gray-200"
            href="https://www.tripadvisor.com/Attractions-g59934-Activities-Greenfield_Wisconsin.html"
          >
            <span class="inline-block p-3 rounded-lg bg-gray-50">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                <path
                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                ></path>
              </svg>
            </span>

            <h6 class="mt-2 font-bold">Entertainment</h6>

            <p class="hidden sm:mt-1 sm:text-sm sm:text-gray-600 sm:block">
              Things to do
            </p>
          </a>

          <a
            class="block p-4 border border-gray-100 shadow-sm rounded-xl focus:outline-none focus:ring hover:border-gray-200 hover:ring-1 hover:ring-gray-200"
            href="https://www.google.com/search?q=Shopping+Greenfield+WI&oq=Shopping+Greenfield+WI&aqs=chrome..69i57.5097j0j4&sourceid=chrome&ie=UTF-8"
          >
            <span class="inline-block p-3 rounded-lg bg-gray-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
            </span>

            <h6 class="mt-2 font-bold">Shopping</h6>

            <p class="hidden sm:mt-1 sm:text-sm sm:text-gray-600 sm:block">
              Covneinient Locations
            </p>
          </a>

          <a
            class="block p-4 border border-gray-100 shadow-sm rounded-xl focus:outline-none focus:ring hover:border-gray-200 hover:ring-1 hover:ring-gray-200"
            href="/doggos"
          >
            <span class="inline-block p-3 rounded-lg bg-gray-50">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                <path
                  d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                ></path>
              </svg>
            </span>

            <h6 class="mt-2 font-bold">Doggos!</h6>

            <p class="hidden sm:mt-1 sm:text-sm sm:text-gray-600 sm:block">
              Neighborhood Dogs!
            </p>
          </a>
        </div>
      </div>
    </div>
  </section>
  <footer class="bg-gray-50">
    <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
        <div>
          <span class="block w-32 h-10 bg-gray-200 rounded-lg"></span>

          <p class="max-w-xs mt-4 text-sm text-gray-500">
            This site is privately maintained by and for the members of the
            Foxwood Crossing Neighborhood.
          </p>

          <div class="flex mt-8 space-x-6 text-gray-500">
            <a
              class="hover:opacity-75"
              href=""
              target="_blank"
              rel="noreferrer"
            >
              <span class="sr-only"> Facebook </span>

              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            <a
              class="hover:opacity-75"
              href=""
              target="_blank"
              rel="noreferrer"
            >
              <span class="sr-only"> Instagram </span>

              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            <a
              class="hover:opacity-75"
              href=""
              target="_blank"
              rel="noreferrer"
            >
              <span class="sr-only"> Twitter </span>

              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                />
              </svg>
            </a>

            <a
              class="hover:opacity-75"
              href=""
              target="_blank"
              rel="noreferrer"
            >
              <span class="sr-only"> GitHub </span>

              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            <a
              class="hover:opacity-75"
              href=""
              target="_blank"
              rel="noreferrer"
            >
              <span class="sr-only"> Dribbble </span>

              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>

        <!-- <div
          class="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4"
        >
          <div>
            <p class="font-medium">Company</p>

            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
              <a class="hover:opacity-75" href=""> About </a>
              <a class="hover:opacity-75" href=""> Meet the Team </a>
              <a class="hover:opacity-75" href=""> History </a>
              <a class="hover:opacity-75" href=""> Careers </a>
            </nav>
          </div>

          <div>
            <p class="font-medium">Services</p>

            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
              <a class="hover:opacity-75" href=""> 1on1 Coaching </a>
              <a class="hover:opacity-75" href=""> Company Review </a>
              <a class="hover:opacity-75" href=""> Accounts Review </a>
              <a class="hover:opacity-75" href=""> HR Consulting </a>
              <a class="hover:opacity-75" href=""> SEO Optimisation </a>
            </nav>
          </div>

          <div>
            <p class="font-medium">Helpful Links</p>

            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
              <a class="hover:opacity-75" href=""> Contact </a>
              <a class="hover:opacity-75" href=""> FAQs </a>
              <a class="hover:opacity-75" href=""> Live Chat </a>
            </nav>
          </div>

          <div>
            <p class="font-medium">Legal</p>

            <nav class="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
              <a class="hover:opacity-75" href=""> Privacy Policy </a>
              <a class="hover:opacity-75" href=""> Terms & Conditions </a>
              <a class="hover:opacity-75" href=""> Returns Policy </a>
              <a class="hover:opacity-75" href=""> Accessibility </a>
            </nav>
          </div>
        </div> -->
      </div>

      <p class="mt-8 text-xs text-gray-500">
        &copy; 2022 Czachendo Creative, LLC
      </p>
    </div>
  </footer>
</template>

<script lang="ts" setup></script>
