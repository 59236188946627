import {
  loadStripe,
  Stripe,
  PaymentIntent,
  StripeError,
  StripeErrorType,
  StripeElementType,
  StripeElements,
  StripeElement,
  StripePaymentElement,
} from "@stripe/stripe-js";
import { initCustomFormatter } from "vue";
import { HoodApi } from "./HoodApi";

let stripe: Stripe | null = null;
let client_secret: string | null = null;

function set_client_secret(secret: string) {
  client_secret = secret;
  // console.log("here i am setting the secret and logging it");
  // console.log(client_secret);
}

function log_secret(): void {
  console.log("Here is the secret from the log function");
  console.log(client_secret);
}

const stripe_key = process.env.VUE_APP_STRIPE_API_KEY;
console.log("Here is the key from env");
console.log(stripe_key);

export const StripeService = {
  init: async (): Promise<void> => {
    stripe = await loadStripe(stripe_key as string);
    // stripe = await loadStripe(
    //   "pk_live_51KzlHSEBxohKpgZBlFMQuZd6XXzZTaQz5AxQILlyNVzfx3WdH4Qbx9bVvFIY2U7CB8gaPvcn4nBHgZIWaZdPCbF500Y0OTlXEE"
    // );
    // stripe = await loadStripe(
    //   "pk_test_51KzlHSEBxohKpgZBXc3QKnBHL8h4YlGtI1CDQFEqo2Y43mOlyLffpmg5Z74RhrMzkxu23qXhxkWZ9CJIUpBf9twZ00nxS9JOoZ"
    // );
  },
  create_elements: async (
    invoice: string
  ): Promise<{ elements: StripeElements }> => {
    try {
      //   console.log(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      if (stripe === null) {
        await StripeService.init();
      }
      const intent: { intent: PaymentIntent; payment: any } =
        await HoodApi.create_dues_payment(invoice);

      console.log(intent);
      const secret = intent.intent.client_secret;
      if (secret) {
        set_client_secret(secret);

        const elements = await stripe?.elements({ clientSecret: secret }); // elements?.create('payment'); // console.log(payment);
        return {
          elements: elements as StripeElements,
        };
      } else {
        throw "Problem";
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  submit_payment: async (elements: StripeElements): Promise<void> => {
    try {
      console.log(
        "we are trying to submit a payment using the following form object"
      );
      const error: { error: StripeError } | undefined =
        await stripe?.confirmPayment({
          elements,
          confirmParams: {
            return_url: "https://foxwoodcrossing.com/payments/success/",
            // return_url: "http://localhost:8080/payments/success/",
          },
        });
      console.log(error);

      if (
        error?.error.type === "card_error" ||
        error?.error.type === "validation_error"
      ) {
        throw error.error;
      } else {
        throw "undefined error";
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  get_payment_status: async (client_secret: string): Promise<any> => {
    // console.log("something ");
    // console.log(client_secret);
    if (stripe === null) {
      await StripeService.init();
    }
    try {
      const paymentIntent = await stripe?.retrievePaymentIntent(client_secret);
      console.log(paymentIntent);
      return paymentIntent;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};
